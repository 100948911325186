import React from "react";

const PricingPlans = () => {
  return (
    <section className="py-16 bg-white text-center">
      <div className="container mx-auto px-6">
        <div className="mb-12">
          <span className="inline-block bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm mb-4">
            JOIN US NOW
          </span>
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Pricing plans
          </h2>
          <p className="text-lg text-gray-700">
            Simple, transparent pricing that grows with you. Try any plan free
            for 30 days.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
          <div className="flex flex-col  bg-white rounded-lg shadow-md p-6 w-full lg:w-1/3 ">
            <h3 className="text-3xl font-bold text-gray-900 mb-2">$215mth</h3>
            <p className="text-gray-700 mb-4">Basic plan</p>
            <p className="text-gray-600 mb-6">
              Ideal for small teams and startups.
            </p>
            <ul className="text-left space-y-4 mb-6">
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Access to core shift management features
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Basic reporting and analytics
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Up to 10 individual users
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                50GB individual data each user
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Email support
              </li>
            </ul>
            <button className="bg-blue-900 text-white py-2 px-4 rounded-lg">
              Get started
            </button>
          </div>

          <div className="flex flex-col bg-white rounded-lg shadow-md p-6 w-full lg:w-1/3 border-4 border-blue-900">
            <span className="inline-block bg-blue-900 text-white px-3 py-1 rounded-full text-sm mb-4">
              Most popular plan
            </span>
            <h3 className="text-3xl font-bold text-gray-900 mb-2">$30/mth</h3>
            <p className="text-gray-700 mb-4">Business plan</p>
            <p className="text-gray-600 mb-6">
              Perfect for growing teams with advanced needs.
            </p>
            <ul className="text-left space-y-4 mb-6">
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Access to all basic features
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Advanced AI-Based Reporting Tool
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Up to 50 individual users
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                200GB data storage per user
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Priority email and chat support
              </li>
            </ul>
            <button className="bg-blue-900 text-white py-2 px-4 rounded-lg">
              Get started
            </button>
          </div>

          <div className="flex flex-col bg-white rounded-lg shadow-md p-6 w-full lg:w-1/3">
            <h3 className="text-3xl font-bold text-gray-900 mb-2">$75/mth</h3>
            <p className="text-gray-700 mb-4">Enterprise plan</p>
            <p className="text-gray-600 mb-6">
              Designed for large organizations with complex requirements.
            </p>
            <ul className="text-left space-y-4 mb-6">
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Access to all basic features
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Virtual Assistant AI Tool
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Unlimited users
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                1TB data storage per user
              </li>
              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                Dedicated account manager
              </li>

              <li className="flex items-center">
                <img
                  src="/assets/check-circle.svg"
                  alt="Check"
                  className="h-5 w-5 mr-2"
                />{" "}
                24/7 priority support
              </li>
            </ul>
            <button className="bg-blue-900 text-white py-2 px-4 rounded-lg">
              Get started
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;
