import React, { useState } from "react";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const faqs = [
    {
      question: "What is ShiftMedix?",
      answer:
        "ShiftMedix is a shift management platform designed specifically for healthcare facilities to optimize staffing and enhance patient care.",
    },
    {
      question: "How does ShiftMedix improve hospital operations?",
      answer:
        "ShiftMedix streamlines scheduling, task management, and staff assignments, making operations more efficient.",
    },
    {
      question: "Is ShiftMedix user-friendly for nursing staff?",
      answer:
        "Absolutely! ShiftMedix is designed with ease of use in mind, ensuring that nursing staff can adopt it with minimal training.",
    },
    {
      question: "Does ShiftMedix have built-in chatting features?",
      answer:
        "Yes, ShiftMedix includes built-in chatting features for seamless communication within your team.",
    },
  ];

  return (
    <section className="py-16 px-4 bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="lg:w-1/2 mb-12 lg:mb-0 text-left lg:pr-8">
          <span className="inline-block bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm mb-4">
            F.A.Q
          </span>
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently asked question
          </h2>
          <p className="text-lg text-gray-700">
            Find quick answers to frequently asked questions about our shift
            management software. Whether it's about features, functionality, or
            how-tos, we've got you covered.
          </p>
        </div>
        <div className="lg:w-1/2 space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-gray-100 rounded-lg shadow-md p-4 cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="text-xl font-semibold flex justify-between items-center">
                {faq.question}
                <span>{openIndex === index ? "-" : "+"}</span>
              </h3>
              {openIndex === index && (
                <p className="text-gray-600 mt-2">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
