import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import PricingSection from "../components/PricingSection";
import TestimonialsSection from "../components/TestimonialsSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import VideoSection from "../components/VideoSection";
import UpcomingSection from "../components/UpcomingSection";
import ShiftManagementSection from "../components/ShiftManagementSection";
import GetSection from "../components/GetSection";

const LandingPage = () => (
  <div className="">
    <Header />
    <div className="px-20">
      <HeroSection />
      <VideoSection />
      <FeaturesSection />
      <UpcomingSection />
      <ShiftManagementSection />
      <PricingSection />
      <TestimonialsSection />
      <GetSection />
      <FAQSection />
    </div>
    <Footer />
  </div>
);

export default LandingPage;
